.App {
  font-family: 'Open Sans', sans-serif;
  flex-direction: row;
  display: flex;
}

.left {
  background-color: #282c34;
  height: 100vh;
  min-width: 230px;
  max-width: 230px;
  color: white;
  padding: 15px;
}

label {
  color: white !important;
}

.right {
  padding: 15px;
}

.readingSchedule {
  column-count: 5;
  column-gap: 50px;
  column-width: 352px;
  column-rule-style: solid;
  column-rule-width: 1px;
  column-rule-color: #e3e3e3;
  padding-bottom: 15px;
  border-bottom: 1px solid #e3e3e3;
}
.unitsPerDay {
  display: flex;
  width: 95px;
  flex-direction: column;
  justify-content: center;
}
.unitsPerDay span, .unitsPerDay div {
  text-align: center;
}
.unitsPerDay span.units {
  font-weight: bolder;
  font-size: 14px;
}
.unitsPerDay span.perDay {
  font-size: 11px;
}
.day {
  text-align: left;
  margin-top: 6px;
}
.date {
  font-weight: bolder;
  width: 150px;
  white-space: nowrap;
  display: inline-block;
}
.chapters {
  display: inline-block;
}

@media print {
  .left { 
    display: none;
  }
}


